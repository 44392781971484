export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9')
];

export const server_loads = [0];

export const dictionary = {
		"/[locale=locale]": [~3,[2]],
		"/[locale=locale]/[thoughts=thoughts]": [~8,[2]],
		"/[locale=locale]/[photographs=photographs]": [~6,[2]],
		"/[locale=locale]/[linkInBio=linkInBio]": [~5,[2]],
		"/[locale=locale]/[about=about]": [~4,[2]],
		"/[locale=locale]/[thoughts=thoughts]/[year]/[month]/[day]/[slug]": [~9,[2]],
		"/[locale=locale]/[photographs=photographs]/[year]/[month]/[day]/[slug]": [~7,[2]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
	
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';